import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'masked'
})
export class MaskedPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';

    var visibleValue = '';
    var maskedValue = '';
    var formattedValue = '';

    if (value.length == 11) {
      visibleValue = value.substring(3, 9);
      maskedValue = '***' + visibleValue + '**';
      formattedValue = maskedValue.substring(0, 3) + '.' + maskedValue.substring(3, 6) + '.' + maskedValue.substring(6, 9) + '-' + maskedValue.substring(9);
    } else if (value.length == 14) {
      visibleValue = value.substring(2, 12);
       maskedValue = '**' + visibleValue + '**';
       formattedValue = maskedValue.substring(0, 2) + '.' + maskedValue.substring(2, 5) + '.' + maskedValue.substring(5, 8) + '/' + maskedValue.substring(8, 12) + '-' + maskedValue.substring(12);
    }
    return formattedValue;
  }
}