import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, exhaustMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthState } from '../state/auth-state/auth.state';
import { AuthService } from '../auth/auth.service';
import { getApiKey, getTenant, getToken } from '../state/auth-state/auth.selectors';
import { getAccountType } from './../state/auth-state/auth.selectors';
import { Endpoints } from 'src/app/shared/endpoints/endpoints';
import { getApiUrl } from 'src/app/core/state/general-state/general.selectors';
import { environment } from 'src/environments/environment';
import { Account } from 'src/app/models/authentication/account';
import { int } from '@zxing/library/esm/customTypings';
import { LoadingService } from 'src/app/shared/loading/loading.service';

@Injectable()
export class HttpInterceptorInterceptor implements HttpInterceptor {

  tenant: string = '';
  apiKey: string = '';
  token: string = '';
  url: string = '';
  accountIndex: string = '';
  endpointsToCheck: Object = {};
  private requests: HttpRequest<any>[] = [];

  constructor(
    private authService: AuthService,
    private router: Router,
    private store: Store<AuthState>,
    private endpoints: Endpoints,
    private loadingService: LoadingService
  ) {
    this.endpointsToCheck = this.endpoints.getEndpoints();
    this.store.select(getTenant).subscribe((tenant) => {
      if (tenant)
        this.tenant = tenant;
    })

    this.store.select(getApiKey).subscribe((apiKey) => {
      if (apiKey)
        this.apiKey = apiKey;
    })

    this.store.select(getToken).subscribe((token) => {
      if (token) {
        this.token = token;
      }
    })

    this.store.select(getAccountType).subscribe((account) => {
      if (account) {
        this.accountIndex = account;
      }
    })

    this.store.select(getApiUrl).subscribe((url) => {
      this.url = url;
    })

  }

  intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<any>> {
    const key = this.authService.key;
    if (this.checkEndpoint(request)) {
      if ((request.method == "GET") || (request.method == "DELETE")) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.token}`,
            'x-api-key': this.apiKey,
            observe: 'response'
          },
          setParams: {
            accountIndex: this.accountIndex,
          }
        });
      } else {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.token}`,
            'x-api-key': this.apiKey,
          },
          body: Object.assign(request.body!, { accountIndex: this.accountIndex })
        });
      }
    } else {
      if (request.url.includes("diletta")) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.token}`,
            'x-api-key': this.apiKey,
            observe: 'response'
          }
        });
      } else {
        request = request
      }

    }

    this.requests.push(request);
    this.loadingService.initializeLoading();

    if ((request.body as any)?.groupedTransactions) {
      this.removeRequest(request);
    }

    return Observable.create((observer: any) => {
      const subscription = next.handle(request)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(request);
              observer.next(event);
            }
          },
          (err: HttpErrorResponse) => {
            this.removeRequest(request);

            if (err.status === 401 || err.status === 403) {
              this.router.navigate(['authentication/login/' + this.tenant]);
              observer.error(err);
            } else {
              observer.error(err);
            }
            observer.error(err);
          },
          () => { this.removeRequest(request); observer.complete(); })
      // teardown logic in case of cancelled requests
      return () => {
        this.removeRequest(request);
        subscription.unsubscribe();
      };
    });
  }

  checkEndpoint(request: HttpRequest<any>): boolean {
    let endpointsToCheck = this.endpoints.getEndpoints()
    let url = this.cleanUrl(request.url)
    let returnValue = false;
    for (let key of endpointsToCheck[request.method]) {
      returnValue = false;
      for (let keyValue in key.split("/")) {
        // primeiro elemento não precisa ser verificado
        if (url.split("/")[+keyValue] == '') {
          continue;
        }
        if ((url.split("/")[+keyValue] == (key.split("/")[+keyValue] ?? "")) ||
          (key.split("/")[+keyValue]?.includes(":") ?? false)) {
          returnValue = true;
        }
        else {
          returnValue = false;
          break;
        }
      }
      if (returnValue)
        break;
    }
    // return endpointsToCheck[request.method].some((el: string) => el == url);
    return returnValue;
  }

  cleanUrl(url: string): string {
    let clearUrl = url.replace(this.url, '');
    if (url.includes(environment.apiUrl)) {
      clearUrl = url.replace(environment.apiUrl, '')
    }
    clearUrl = clearUrl.replace(/(\/v[0-9])\/d[a-zA-Z]+/, '');
    return clearUrl
  }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
      if (this.requests.length === 0) {
        this.loadingService.finalizeLoading();
      }
    }

  }

}