export const environment = {
  envName: 'PROD',
  production: true,
  firebase: {
    apiKey: "AIzaSyCFJmlYM8t-0JATPyXxE7enLrUXwXo87Tc",
    authDomain: "aden-bank-427020.firebaseapp.com",
    projectId: "aden-bank-427020",
    storageBucket: "aden-bank-427020.appspot.com",
    messagingSenderId: "205617748791",
    appId: "1:205617748791:web:e8be9a6ece3d8fd2c658b2"
  },

  tenants: [
    {
      tenant: "aden-bank-k4jj4",
      name: "adenbank",
      primaryColor: "#FF7300",
      overPrimary: "#000000",
      icon: "https://storage.googleapis.com/aden-bank-427020.appspot.com/tenant-assets/aden-bank/icon",
      apiKey: "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJEaWxldHRhIFNvbHV0aW9ucyIsImlhdCI6MTcxOTAyNzQ2NTg4MSwiYXVkIjoiYWRlbi1iYW5rIiwic3ViIjoiYWRlbi1iYW5rIiwianRpIjoiNWEwMjY3YTUtMDJkNy00N2Y5LWFlM2MtMzgyNWIzZGRhYWY4IiwidGVuYW50SWQiOiJhZGVuLWJhbmstazRqajQifQ.RdvIwPvmzChWq1JHdrtdMfA-As42BqyPjejYeFXUnV0YQONOxu3K3qnY-55VEqDDWNkKNQWJ2kEV_kGXdct5hA",
      client: "matera",
      clientSuite: true,
      infoHeader: false,
      biz: false,
      apiUrl: 'https://aden-bank-427020.uc.r.appspot.com',
      appSupport: true,
      orderSupport: false,
      isAccumulatedBalanceEnabled: true
    }
  ],
  apiUrl: 'https://aden-bank-427020.uc.r.appspot.com',
  keyEncryptAuth: 'oGTy7QtyHr48GE6re5KTckkGnbm6oo7SwRHsi2gqjapqflY9hjTAJ4GyUtt9YrSK',
  account: '413453',
  branch: '3100',
  wallet: '109',
  bank: '341',
  currency: '9'
};